import request from '@/util/request'
/**
 * 字典查询
 * 参数 ：
 */
export const dict = (id,params) => {
    return request({
        url: `/api/system/Base/DictionaryData/${id}/Data/Selector`,
        method: 'get',
        params
    })
}

// 获取数据字典列表
export function getDictionaryDataList(typeId, data) {
    return request({
      url: `/api/system/Base/DictionaryData/${typeId}`,
      method: 'GET',
      data
    })
  }

    // 新增数据字典
export function addDictionaryData(data) {
  return request({
    url: `/api/system/Base/DictionaryData`,
    method: 'POST',
    data
  })
}