<template>
  <div class="wrapper-content">
    <!-- 赛事查询 -->
    <el-form :inline="true" :model="queryParams" ref="searchForm" label-width="50px" class="search-bar">
      <el-form-item label="赛事:" prop="name">
        <el-input type="age" v-model="queryParams.competitionName" placeholder="请输入赛事名称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="submitForm()">搜索</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 赛事筛选 -->
    <div class="screening-bar">
      <ul>
        <li>
          <span class="screening-title"><i class="el-icon-location-outline" /> 地址:</span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(1, '','')" :class="{ active: queryParams.province == '' }">不限</span>
            <el-dropdown v-for="item in regionData" :key="item.value" @command="handleCommand" size="medium">
              <span class="el-dropdown-link screening-span" :class="{ active: queryParams.province == item.label }" @click="provinceClick(1, item.value,item.label)">{{ item.label }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="items in item.children" :key="items.value" :command="composeValue(item.value, items.value,item.label,items.label)">{{ items.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <a href="javascript:;" :key="0" @click="moreItem" class="screening-more">更多<i class="el-icon-arrow-down"/></a>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-menu" /> 项目: </span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(2, '')" :class="{ active: queryParams.itemCode == '' }">不限</span>
            <span class="screening-span" v-for="(item, i) in projectData" :key="i" @click="provinceClick(2, item.enCode, item.fullName)" :class="{ active: item.enCode == queryParams.itemCode }">{{ item.fullName }}</span>
          </div>
          <a href="javascript:;" :key="0" @click="moreItem" class="screening-more">更多<i class="el-icon-arrow-down"/></a>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-time" /> 时间: </span>
          <div class="screening-list">
            <el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="赛事开始日期" end-placeholder="赛事结束日期" style="margin-left: 6px" value-format="yyyy-MM-dd" @change="pickChange"></el-date-picker>
          </div>
        </li>
        <li>
          <span class="screening-title"><i class="el-icon-wallet" /> 费用:</span>
          <div class="screening-list">
            <span class="screening-type" @click="provinceClick(3, '')" :class="{ active: queryParams.collectFee === '' }">不限</span>
            <span class="screening-span" v-for="(item, i) in costData" :key="i" @click="provinceClick(3, item.value)" :class="{ active: item.value === queryParams.collectFee }">{{ item.name }}</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 赛事活动 -->
    <div class="in_competition">
      <template v-if="cList && cList.length > 0">
        <div class="in_competition-item" v-for="item in cList" :key="item.competitionId" @click="goDetail(item.id)">
          <el-image class="in_competition-img" :src="loadUrl(item.image)" fit="cover"></el-image>
          <div class="in_competition-text">
            <h3>
              <!-- 1未报名 2报名中 3报名结束 -->
              <el-tag v-if="item.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
              <el-tag v-if="item.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
              <el-tag v-if="item.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
              <el-tag v-if="item.status == 9" effect="dark" size="mini">比赛中</el-tag>
              <el-tag v-if="item.status == 10" type="info" effect="dark" size="mini">比赛已结束</el-tag>
              {{ item.name }}
            </h3>
            <span class="date-text">比赛时间： {{ formatDateH(item.gameStartTime) }} 至 {{ formatDateH(item.gameEndTime) }}</span>
            <span class="date-text">比赛地点： {{ showAddress(item) }} </span>
            <!-- <span class="price"><i>¥</i>388.00</span> -->
          </div>
        </div>
      </template>
      <el-empty v-else description="暂时无数据"></el-empty>
    </div>
    <!-- page -->
    <el-pagination v-if="total > 0" class="page-bar" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryParams.currentPage" :page-sizes="[8, 16, 24, 32]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
import { getDictionaryDataList } from "@/api/dict";
import { getCompetitionList, getOps } from "@/api/events";
import { CodeToText, regionData } from "element-china-area-data";
export default {
  name: "events-index",
  components: {},
  data() {
    return {
      searchForm: {
        name: "",
      },
      tagItems: [
        { type: "", label: "无效" },
        { type: "success", label: "筹备中" },
        { type: "info", label: "已结束" },
        { type: "danger", label: "报名中" },
        { type: "warning", label: "进行中" },
      ],
      cList: [],
      total: 0,
      accountData: {},
      queryParams: {
        currentPage: 1,
        pageSize: 8,
        province: "",
        city: "",
        gameStartTime: "",
        gameEndTime: "",
        itemCode: "",
        itemName: "",
        collectFee: "",
        keyword: "",
        status: "5,7,8,9,10",
      },
      dataes: [
        {
          name: "地址",
          icon: "el-icon-location-outline",
          type: "不限",
          list: ["北京", "上海", "天津", "重庆", "云南", "四川", "安徽", "广东", "江苏", "江西", "河北", "浙江", "海外", "湖南", "福建", "贵州", "辽宁", "陕西", "香港", "澳门", "台湾",],
          isShow: true,
        },
        {
          name: "类型",
          icon: "el-icon-menu",
          type: "不限",
          list: ["越野", "跑马", "拉松半程", "马拉松", "10k", "迷你马拉松", "主题跑", "徒步", "活动", "骑游", "骑跑两项", "铁人三项", "户外", "挑战赛", "超级马拉松", "定向赛乐",],
          isShow: true,
        },
        {
          name: "时间",
          icon: "el-icon-time",
          type: "不限",
          list: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月",],
          isShow: true,
        },
        {
          name: "费用",
          icon: "el-icon-wallet",
          type: "不限",
          list: ["免费", "100以下", "101-200", "201-300", "300以上"],
          isShow: true,
        },
        {
          name: "报名",
          icon: "el-icon-edit-outline",
          type: "不限",
          list: ["官网报名", "线下报名"],
          isShow: false,
        },
      ],
      classType0: "地址_-1",
      classType1: "类型_-1",
      classType2: "时间_-1",
      classType3: "费用_-1",
      classType4: "报名_-1",
      isMore: false,
      currentPage: 2,
      // postalAddress
      regionData: regionData,
      dateValue: "",
      projectData: [],
      costData: [
        { name: "免费", value: 0 },
        { name: "收费", value: 1 },
      ],
      itemCode: "261a37e11630402c9773616a62601450",
    };
  },
  watch: {
    // dateValue(val) {
    //   if (!val) {
    //     this.queryParams.startDate = "";
    //     this.queryParams.endDate = "";
    //     this.init();
    //   }
    // },
  },
  mounted() {
    // 数据字典
    getDictionaryDataList(this.itemCode).then((res) => {
      this.projectData = res.data.list;
    });
    this.init();
    this.$store.dispatch("saveHeadActive", "/events");
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      let tid = window.localStorage.getItem("tenant_id");
      self.$set(self.queryParams, "tenantId", tid);
      getCompetitionList(this.queryParams).then((res) => {
        if (res.code == 200) {
          self.cList = res.data.list;
          self.total = res.data.pagination.total;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // 时间选择
    pickChange(val) {
      if(val){
        this.queryParams.gameStartTime = val[0];
        this.queryParams.gameEndTime = val[1];
      }else {
        this.queryParams.gameStartTime = '';
        this.queryParams.gameEndTime = '';
      }
      this.$nextTick(() => {
        this.init();
      });
    },
    // 省份点击事件
    provinceClick(num, val, name) {
      switch (num) {
        case 1:
          this.queryParams.province = name;
          this.queryParams.city = "";
          break;
        case 2:
          this.queryParams.itemCode = val;
          this.queryParams.itemName = name;
          break;
        case 3:
          this.queryParams.collectFee = val;
          break;
      }
      this.$nextTick(() => {
        this.init();
      });
    },
    // 市区点击事件
    handleCommand(val) {
      this.queryParams.city = val.cLable;
      this.queryParams.province = val.pLabel;
      this.$nextTick(() => {
        this.init();
      });
    },
    composeValue(province, city,pLabel,cLable) {
      return {
        province: province,
        city: city,
        pLabel:pLabel,
        cLable:cLable
      };
    },
    // 回显地址
    showAddress(item) {
      return `${item.province}${item.city}${item.area}${item.address}`;
    },
    submitForm() {
      this.init();
    },
    resetForm() {
      this.queryParams =  {
        currentPage: 1,
        pageSize: 8,
        province: "",
        city: "",
        gameStartTime: "",
        gameEndTime: "",
        itemCode: "",
        itemName: "",
        collectFee: "",
        keyword: "",
        status: "5,7,8,9,10",
      }
      this.init();
    },
    addClassFun(index, name, i) {
      switch (index) {
        case 0:
          this.classType0 = name + "_" + i;
          break;
        case 1:
          this.classType1 = name + "_" + i;
          break;
        case 2:
          this.classType2 = name + "_" + i;
          break;
        case 3:
          this.classType3 = name + "_" + i;
          break;
        case 4:
          this.classType4 = name + "_" + i;
          break;
      }
    },
    classType(index) {
      switch (index) {
        case 0:
          return this.classType0;
          break;
        case 1:
          return this.classType1;
          break;
        case 2:
          return this.classType2;
          break;
        case 3:
          return this.classType3;
          break;
        case 4:
          return this.classType4;
          break;
      }
    },
    moreItem(el) {
      let _el = el.currentTarget;
      let prev = el.currentTarget.previousElementSibling;
      _el.innerHTML = "";

      this.isMore = !this.isMore;
      if (!this.isMore) {
        _el.innerHTML = '更多<i class="el-icon-arrow-down" />';
        prev.style.height = "40px";
      } else {
        _el.innerHTML = '收起<i class="el-icon-arrow-up" />';
        prev.style.height = "auto";
      }
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.init();
    },
    goDetail(id) {
      this.$router.push(`events-detail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  font-size: 14px;
  color: #555;
  padding-top: 28px;
}

.screening-bar {
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: top;
    // padding: 10px 0;
    font-size: 14px;
    line-height: 20px;
    color: #555;
  }
  .screening-title {
    margin: 10px 5px;
    font-size: 14px;
    color: #555;
    flex-shrink: 0;
  }
}

.screening-type,
.screening-list .screening-span {
  display: inline-block;
  cursor: pointer;
  margin: 10px 5px;
  padding: 0 10px;
  color: #555;
  border-radius: 3px;
}
.screening-type,
.screening-more {
  flex-shrink: 0;
  height: 20px;
}
.screening-list {
  flex-grow: 1;
  height: 40px;
  overflow: hidden;
}
.screening-more {
  margin-top: 10px;
  color: #999;
}

.screening-bar li .screening-type:hover,
.screening-bar li .screening-type.active,
.screening-bar li .screening-span:hover,
.screening-bar li .screening-span.active {
  background-color: #1e50ae;
  color: #fff;
}

.page-bar {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 60px;
}
</style>
